@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-display: swap;
  font-family: "Noto Sans", sans-serif !important;
}

table td,
table td * {
  vertical-align: top;
}

.containerHover {
  padding: 1em 0;
  float: left;
  width: 50%;
}

@media screen and (max-width: 640px) {
  .containerHover {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .containerHover {
    width: 33.33333%;
  }
}

.containerHover .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: #c7081bdb;
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
  height: 200px;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

/* Modal css Start */

.ReactModal__Overlay {
  background-color: rgb(0 0 0 / 25%) !important;
}

.ReactModal__Content {
  width: 300px !important;
  z-index: 10 !important;
  top: 20% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 768px) {
  .ReactModal__Content {
    inset: 80 !important;
    top: 18% !important;
    width: 60% !important;
  }
}

/* Modal css End */

.sliderClass {
  background: white;
  height: 300px;
  margin: auto;
  margin-top: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sliderClass::before,
.sliderClass::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.sliderClass::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.sliderClass::before {
  left: 0;
  top: 0;
}

.sliderClass .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.sliderClass .slide {
  margin: 20px;
  border-radius: 10%;
  height: 240px;
  width: 100%;
  color: whitesmoke !important;
  background: linear-gradient(90deg, rgb(255 255 255) 50%, rgb(199 8 27) 50%);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 60px !important;
  width: 30px !important;
  position: absolute;
  margin: 20px !important;
  top: 50% !important;
}

a {
  color: inherit !important;
}

a:hover {
  color: inherit !important;
}

.font h6 {
  font-size: 15px;
  font-weight: 600;
  margin: 1px;
}

.font span {
  font-size: 10px;
}

.thumbs.animated {
  margin: 0 !important;
}

.headerClass {
  background: rgba(0, 0, 0, 0.589);
  backdrop-filter: saturate(180%) blur(5px);
}

.active_nav_bar {
  background-color: #3a7f44;
}

.non_active_nav_bar:hover {
  transition: all 0.3s linear;
  background-color: #fce94f !important;
  color: #000000;
}

.active_nav_bar:hover {
  transition: all 0.3s linear;
  background-color: #fce94f !important;
  color: #000;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #a76a6a !important;
}

.color {
  color: #c7081b !important;
}

.imagesBackground {
  background: url("../assets/Slider-Compacts-v1-2.jpg") top center no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 330px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.containerClass {
  position: relative;
  text-align: center;
  color: white;
}

footer {
  background-color: #333333 !important;
}

footer a:hover {
  color: white !important;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.spinner {
  display: block;
  margin: auto;
  height: 10em;
  width: 10em;
  border: 6px solid rgba(0, 174, 239, 0.2);
  border-top-color: rgba(0, 174, 239, 0.8);
  border-radius: 50%;
  animation: rotation 0.6s infinite linear;
}

.hover-trigger .hover-target {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  top: 2.5rem;
}

.hover-trigger:hover .hover-target {
  visibility: visible;
  opacity: 1;
  top: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.carousel .slide img {
  max-height: 650px;
  /* change this to whatever you want */
  width: auto;
  object-fit: cover !important;
}

.carousel .thumb img {
  width: 80px !important;
  height: 39px !important;
}

/* ---- Card ------ */
.static-top-widget .media-body .icon-bg {
  position: absolute;
  right: -14px;
  top: 3px;
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100px;
  height: 100px;
  font-size: 85px;
}

.static-top-widget:hover .icon-bg {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.position {
  bottom: 25px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0px !important;
}

/*-- Loader ---*/

.loader {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 9999;
}

.loader span {
  font-size: 40px;
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 1;
  mix-blend-mode: difference;
}

.loader::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 100%;
  background-color: white;
  animation: move 4s linear infinite;
  -webkit-animation: move 4s linear infinite;
}

@keyframes move {
  0%,
  100% {
    left: 0;
  }

  50% {
    left: calc(100% - 100px);
  }
}

.capitalize::first-letter {
  text-transform: uppercase;
}

.is-active {
  transition-duration: 1s;
  background-position: -2800px 0;
}

.react-slider__picture {
  height: 475px !important;
}

.react-slider__ul {
  margin: 5px 0 0 !important;
  justify-content: center !important;
}

.react-slider__ul li {
  height: 75px !important;
}

.react-slider__ul li.active img {
  border: 3px solid #d69e2e !important;
}

.hand {
  background-color: #fff2b2 !important;
}

.like {
  background-color: #fbc9c9 !important;
}

.flip-container {
  perspective: 1000;
  -webkit-perspective: 1000;
  background: transparent;
}

/* flip the picture when hover */

.flip-container:hover .flipper {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 100%;
  height: 240px;
}

/* flip speed */

.flipper {
  transition: 0.6s;
  -webkit-transition: 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  position: relative;
}

/* hide back  during flip */

.front,
.back {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front placed above back */

.front {
  z-index: 2;
}

/* back, initially hidden */

.back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  background: #c7081b !important;
  text-align: center;
}

i.button_icon {
  border: 2px solid #fff;
  /* border-radius: 50%; */
  width: 44px;
  height: 44px;
  line-height: 24px;
  transition-duration: 0.2s;
  font-size: 18px;
  padding-top: 8px;
  margin-right: 5px;
  margin-left: 5px;
}

.hover_title {
  margin-top: 65px;
  font-weight: 500;
  font-size: 24px !important;
  color: #fff;
  margin-bottom: 14px;
}

.flipper .back i.button_icon:before {
  color: #fff;
}

.flipper .back i.button_icon:hover:before {
  color: #c7081b;
}

.flipper .back i.button_icon:hover {
  background-color: #fff;
}

.firstcharacter {
  float: left;
  color: #c7081b;
  font-size: 70px;
  line-height: 58px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 45px;
}
input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  /* font-size:18px; */
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -20px;
  color: #c7081b;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #c7081b;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.1;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #c7081b;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #c7081b;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #c7081b;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.underline {
  text-decoration: none !important;
}
